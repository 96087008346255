// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import Alpine from 'alpinejs'

Alpine.magic('clipboard', () => {
  return (text) => {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  }
})

window.Alpine = Alpine
Alpine.start()
